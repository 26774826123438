.viewScenarioAnalyzerInput {
  margin-top: 10%;
}

/* .ViewScenarioAnalyzer{
    margin: 1rem 5px 5rem 5px;
} */
.viewScenarioAnalyzerInputField {
  padding-top: 5%;
  padding-right: 5%;
  display: flex;
  justify-content: center;
}

.btn-viewScenarioAnalyzer {
  margin-left: 3rem;
}

#running-component {
  width: 100%;
  height: 100vh;
  /* Add your component styles here */
}

#gray-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(.5px);
  background-color: rgba(128, 128, 128, 0.5);
  /* Adjust the opacity here */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5776e7;
  font-size: 30px;
}

#coming-soon-message {
  text-align: center;
  font-style: italic;
  font-weight: 600;
  color: white;
  /* Text color */
  backdrop-filter: blur(.5px);
  background-color: #5776e7;
  width: 100%;
}

#coming-soon-message h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

#coming-soon-message p {
  font-size: 18px;
  margin-top: 0;
}

.my-table-wrapper .ant-table-thead>tr>th,
.my-table-wrapper .ant-table-tbody>tr>td {
  font-size: 11px;
  font-weight: 500;
  padding: 4px 8px;
}

.name {
  font-size: small;

  font-weight: 200;
}

.Card:not(.chart):hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.Card {
  margin: 0.5rem;
}

.title {
  margin: .5rem 0.5rem 0.5rem 0;
  font-family: sans-serif;
  font-size: large;
  font-weight: 100;
}

.AnalyzerView {
  background-color: rgb(231, 236, 238);
}

.chartLabelBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.chartLabel {
  font-weight: 300;
  font-size: medium;
}

.AnalyzerTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AnalyzerBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.analyserExport {
  margin: 1rem 1rem 0.5rem 0.5rem;
  font-family: sans-serif;
  font-size: small;
  font-weight: 300;
}

.tableWrap {
  width: 60%;
  margin-top: 1rem;
}

.tableCard {
  display: flex;
  flex-direction: row;
}

.setWidhth {
  width: 5rem;
}

.btn-list {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.histChart {
  width: 100%;
  display: flex;
  flex-basis: auto;
  justify-content: center;
  justify-items: center;
  padding: '5%'
}

.chartSelector {
  width: 50%
}

.weekSelector {
  width: 39%;
  padding-left: 1%;
  margin-right: 1%;
}

.AnalyserChart {
  background-color: #ffffff;
  height: 100%;
  padding:1%
}
