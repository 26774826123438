.section1 img {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center; /* center the image */
}
.branding {
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
}

.branding img {
  margin-right: 2rem;
  width: 35%; /* set the width to 200 pixels */
  mix-blend-mode: multiply;
  height: 35%; /* set the height to 200 pixels */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  /* background-color: red; */
  /* mix-blend-mode: overlay; */
}

.text {
  position: relative;
  z-index: 10;
  padding-left: 2rem;
  width: 60%;
}
.text img {
  width: 80%;
  height: 50%;
}
.text h2 {
  font-size: 8em;
  font-weight: 300;
  text-transform: uppercase;
}
.text h3 {
  font-size: 3.5em;
  font-weight: 550;

  color: white;
  text-transform: uppercase;
}
.text p {
  margin-left: 60%;
  font-size: 1.5em;
  font-weight: 300;
  color: rgb(255, 255, 255);
}
button {
  text-decoration: none;
  border: none; /* remove the border */
}
.text button {
  display: flex;
  font-size: 1em;
  font-weight: 400;
  padding: 10px 30px;
  text-decoration: none;
  background: rgb(246, 172, 25);
  color: rgb(253, 253, 253);
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 5px;
  transition: 0.5s;
  border: none;
  border-radius: 10px;
}
.text button:hover {
  letter-spacing: 10px;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: grey;
  display: flex;
  justify-content: center;
  z-index: 10;
}

@media (max-width: 700px) {
  .banerzzz {
    padding: 30px;
  }
  .text h2 {
    font-size: 4em;
  }
  .text h3 {
    font-size: 2em;
  }
  .text p {
    font-size: 1em;
  }
}
